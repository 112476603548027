<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Select',
    },
    id: {
      type: String,
      default: 'customNakedDropdown',
    },
    theme: {
      type: String,
      default: 'dark',
    },
    dark: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    defaultValue: {
      type: [Number, Object],
      default: () => [],
      required: true,
    },
    onChange: {
      type: Function,
      default: () => {},
      required: false,
    },
    icon: {
      type: String,
      default: 'dropdown',
    },
  },
}
</script>

<template>
  <div :id="id" :class="theme">
    <span class="prepend-text">{{ label }}</span>
    <v-select
      :value="defaultValue"
      :items="items"
      text
      solo
      @change="$emit('change', $event)"
    ></v-select>
  </div>
</template>
