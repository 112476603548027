var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-stats"},[(_vm.showReclaimNotification)?_c('v-alert',{staticClass:"reclaim-reminder",attrs:{"color":"#e36515","dark":"","type":"warning","dense":""}},[_vm._v(" "+_vm._s(_vm.$t('RECLAIM_PERIOD_NOTICE', { period: _vm.lastSubmissionDateForQuarter.toLocaleDateString(), }))+" ")]):_vm._e(),_c('div',{staticClass:"status-overview"},[_c('div',{staticClass:"top-info"},[_c('h1',[_vm._v(_vm._s(_vm.$t(`ALPHA3_COUNTRY.${_vm.$route.params.country}`))+", "+_vm._s(_vm.year))]),(!_vm.isReadOnlyUser)?_c('v-btn',{staticClass:"btn ondark-btn addExpenseButton",on:{"click":_vm.addExpense}},[_vm._v("+ "+_vm._s(_vm.$t('ADD_EXPENSE')))]):_vm._e(),_c('div',{staticClass:"reclaim"},[_c('v-tooltip',{attrs:{"disabled":!_vm.tooltipText.length,"max-width":"350px","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_c('v-btn',{staticClass:"btn primary-btn reclaim",attrs:{"loading":_vm.loading,"disabled":_vm.isReadOnlyUser || _vm.reclaimButtonDisabled},on:{"click":_vm.createReclaim}},[_vm._v(_vm._s(_vm.reclaimButtonText))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.tooltipText))])])],1)],1)]),_c('v-row',[_c('div',{staticClass:"status-cards"},[_c('StatusCard',{attrs:{"title":_vm.$t('NEED_MORE_INFO'),"amount":_vm.cardsInfo[1].display,"progress":_vm.cardsInfo[1].percentage,"expenses":_vm.cardsInfo[1].expenses,"color":"#ffb920","background-color":"bgcolor__grey-dark","term":_vm.cardsInfo[1].expenses
            ? _vm.cardsInfo[1].expenses === 1
              ? 'STATUS_CARD_NEEDS_INFO_SINGULAR'
              : 'STATUS_CARD_NEEDS_INFO'
            : 'NO_EXPENSES_IN_THIS_STATUS'}}),_c('StatusCard',{attrs:{"title":_vm.$t('DESELECTED'),"amount":_vm.cardsInfo[2].display,"progress":_vm.cardsInfo[2].percentage,"expenses":_vm.cardsInfo[2].expenses,"color":"#f05e00","background-color":"bgcolor__grey-dark","add-expense-button":"","term":_vm.cardsInfo[2].expenses === 1
            ? 'STATUS_CARD_DESELECTED_SINGULAR'
            : 'STATUS_CARD_DESELECTED'}}),_c('StatusCard',{attrs:{"title":_vm.$t('NON_REFUNDABLE'),"amount":_vm.cardsInfo[3].display,"progress":_vm.cardsInfo[3].percentage,"expenses":_vm.cardsInfo[3].expenses,"color":"#ff001e","background-color":"bgcolor__grey-dark","term":_vm.cardsInfo[3].expenses === 1
            ? 'STATUS_CARD_NON_REFUNDABLE_SINGULAR'
            : 'STATUS_CARD_NON_REFUNDABLE'}}),_c('StatusCard',{attrs:{"title":_vm.$t('READY_TO_RECLAIM'),"amount":_vm.cardsInfo[0].display,"progress":_vm.cardsInfo[0].percentage,"expenses":_vm.cardsInfo[0].expenses,"color":"#00deb2","background-color":"bgcolor__grey-dark","term":_vm.cardsInfo[0].expenses
            ? _vm.cardsInfo[0].expenses === 1
              ? 'STATUS_CARD_READY_TO_RECLAIM_SINGULAR'
              : 'STATUS_CARD_READY_TO_RECLAIM'
            : 'NO_EXPENSES_IN_THIS_STATUS'}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }