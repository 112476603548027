<script>
import Layout from '@layouts/main/main'
import i18n from '@src/i18n'
import ExpensesByCountry from '@components/expenses-by-country/expenses-by-country'

export default {
  page: {
    title: i18n.t('EXPENSES'),
    meta: [{ name: 'description', content: 'ExpensesByCountry' }],
  },
  components: { Layout, ExpensesByCountry },
}
</script>

<template>
  <Layout>
    <ExpensesByCountry />
  </Layout>
</template>
