<script>
import { getSavedStateSession, saveStateSession } from '@utils/storage'
import eventBus from '@src/event-bus'

import {
  expensesByCountryComputed,
  expensesByCountryMethods,
  expensesByCountryMutations,
} from '@state/helpers/expensesByCountry'
import { generalMethods } from '@state/helpers/general'

import StatusOverview from '@components/status-overview/status-overview'
import ExpandableCategories from '@components/expandable-categories/expandable-categories'
import NakedDropdown from '@components/naked-dropdown/naked-dropdown'
import { getYears } from '@utils/getYears'
import i18n from '@src/i18n'
import { enterpriseComputed } from '@state/helpers/enterprise'
import { pick } from 'lodash'
import { lunadisPayOrigin } from '@/src/constants/origins'

export default {
  components: {
    StatusOverview,
    ExpandableCategories,
    NakedDropdown,
  },
  data() {
    return {
      options: {
        data: getYears(),
      },
      reclaimButtonText: null,
      reclaimButtonDisabled: false,
      tooltipText: '',
    }
  },
  computed: {
    ...expensesByCountryComputed,
    ...pick(enterpriseComputed, ['currentEnterprise']),
    ...enterpriseComputed,
    quarterToReclaim() {
      const today = new Date()
      const currentYear = today.getFullYear()
      if (this.year === currentYear) {
        const mm = today.getMonth() + 1 // January is 0!
        return this.calculateLatestReclaimableQuarterByOriginatingSystem(
          mm,
          this.currentEnterprise.originating_system
        )
      }
      return 4 // For previous years we always reclaim Q4
    },
    country() {
      return i18n.t(`ALPHA3_COUNTRY.${this.$route.params.country}`)
    },
    needMoreInfoSummary() {
      return this.summary
        ? this.summary.find((item) => item.status === 'need_more_info')
        : null
    },
    readyToReclaimSummary() {
      return this.summary
        ? this.summary.find((item) => item.status === 'ready_to_reclaim')
        : null
    },
  },
  watch: {
    enterpriseId(newValue, oldValue) {
      if (newValue && oldValue !== newValue) {
        this.updateExpenses()

        this.getReclaimedQuarters()

        // when using direct link expense, we set current enterprise using the
        // enterprise id from the direct link expense.We also set the year the
        // same way but since it can't be done at the same tme, we have to wait
        // to have both values in order to make certain requests.
        if (this.year) {
          this.updateCategoryOverview()
          this.getSummary({
            country: this.$route.params.country,
            year: this.year,
          })
        }
      }
    },
    summary(newValue, oldValue) {
      this.configureReclaimButton()
    },
    reclaimedQuarters(newValue, oldValue) {
      this.configureReclaimButton()
    },
    reclaimLimits(newValue, oldValue) {
      this.configureReclaimButton()
    },
    year() {
      this.getReclaimedQuarters()
    },
  },
  async beforeMount() {
    // if using direct link for an expense
    const directLinkExpense = getSavedStateSession('expense')
    if (directLinkExpense) {
      await this.directLinkExpenseHandler(directLinkExpense)
    }
    if (!this.year) {
      const year = getSavedStateSession('expensesByCountry.year')
      year && this.setYear(year)
    }
    const country = this.$route.params.country
    this.setCountry(country)
    this.resetExpensesByCountry()
    this.getSummary({
      country: country,
      year: this.year,
    })
    this.getReclaimedQuarters()
    this.fetchReclaimLimits(country)
    this.updateCategoryOverview()
    if (!this.categories.length) {
      this.getCategories()
      this.$store.dispatch('categories/fetchCategories', {}, { root: true })
    }
  },
  methods: {
    ...expensesByCountryMethods,
    ...generalMethods,
    ...expensesByCountryMutations,

    async directLinkExpenseHandler({ id, year, enterpriseId }) {
      // if direct link expense is not a part of the currently chosen enterprise:
      if (
        this.currentEnterprise &&
        this.currentEnterprise.id !== enterpriseId
      ) {
        // set the enterprise to match the one from direct link expense
        eventBus.$emit('growl', {
          title: 'Change enterprise warning',
          description: i18n.t('CHANGE_ENTERPRISE_WARNING'),
          type: 'notice',
        })
      }

      await this.$store.dispatch('enterprise/getSelectedEnterprise', {
        enterpriseId,
        disableRedirect: true,
      })

      // set overview and expense by country data to match the one from direct link expense
      this.setYear(year)
      this.$store.dispatch('expenseOverview/setYear', year, {
        root: true,
      })
      saveStateSession('expenseOverview.year', year)
      // initialize expense
      this.$store.dispatch('expenseModal/initializeExpense', {
        id,
      })
    },
    selectCategory(category) {
      this.setCategory(category)
      this.updateExpenses()
    },
    selectStatus(status) {
      this.setStatus(status)
      this.setCategory(null)

      const nextSort =
        this.status === 'NEED_MORE_INFO' ? 'amount_incl_vat' : 'date_modified'
      if (this.sort !== nextSort) {
        this.setSort(nextSort)
      }

      this.updateExpenses()
      this.updateCategoryOverview()
      this.setPage(1)
      this.$amplitude.getInstance().logEvent('FILTER_EXPENSES_BY_STATUS', {
        status: status,
      })
    },
    onSetPage(page) {
      this.setPage(page)
      this.updateExpenses()
    },
    onSetPageSize(pageSize) {
      this.setPagination({ ...this.pagination, pageSize })
      this.updateExpenses()
    },
    onSetYear(year) {
      saveStateSession('expensesByCountry.year', year)
      this.setYear(year)
      this.setCategory(null)
      this.getSummary({
        country: this.$route.params.country,
        year: year,
      })

      this.updateExpenses()
      this.updateCategoryOverview()
    },
    checkBoxClick({ value, id }) {
      const selection = [...this.selectedExpenses]
      if (value) {
        selection.push(id)
      } else {
        selection.splice(selection.indexOf(id), 1)
      }
      this.setSelectedExpenses(selection)
    },
    selectAllClick(value) {
      const selection = [...this.selectedExpenses]
      if (value) {
        // Select all
        this.expenses.forEach((expense) => {
          if (selection.indexOf(expense.id) === -1) {
            selection.push(expense.id)
          }
        })
      } else {
        // Deselect all
        this.expenses.forEach((expense) => {
          selection.splice(selection.indexOf(expense.id), 1)
        })
      }
      this.setSelectedExpenses(selection)
    },
    onMoveExpenses(status) {
      this.moveExpenses({ status, expenseIds: this.selectedExpenses })
    },
    updateExpenses() {
      this.deselectExpenses()

      if (this.category) {
        this.getExpenses({
          category: this.category,
          status: this.status,
          country: this.$route.params.country,
          enterprise_id: this.enterpriseId,
          year: this.year,
          page: this.pagination.page,
          page_size: this.pagination.pageSize,
          sort: this.sort,
          order: this.order,
        })
      }
    },
    updateCategoryOverview() {
      this.getCategoriesOverview({
        country: this.$route.params.country,
        year: this.year,
        status: this.status,
        enterprise_id: this.enterpriseId,
      })
    },
    // This is called by watcher when either reclaimedQuarters or summary API calls are resolved
    configureReclaimButton() {
      if (
        Array.isArray(this.reclaimedQuarters) &&
        Array.isArray(this.summary) &&
        this.reclaimLimits &&
        this.reclaimLimits.country === this.$route.params.country
      ) {
        const { text, reclaimButtonDisabled, tooltipText } =
          this.getReclaimButtonInfo(
            this.year,
            this.reclaimedQuarters,
            this.summary
          )
        this.reclaimButtonText = text
        this.tooltipText = tooltipText
        this.reclaimButtonDisabled = reclaimButtonDisabled
      }
    },
    // This returns reclaim button text, tooltipText and whether it should be disabled
    getReclaimButtonInfo(selectedYear, reclaimedQuarters, summary) {
      let reclaimButtonDisabled = false
      let text = ''
      let tooltipText = ''

      // First check if there is something to reclaim
      if (
        !this.readyToReclaimSummary ||
        !this.readyToReclaimSummary.sum_reclaimable_country_currency
      ) {
        reclaimButtonDisabled = true
        text = i18n.t('NO_EXPENSES_TO_RECLAIM')
        tooltipText = i18n.t('NO_EXPENSES_TO_RECLAIM_TOOLTIP')
        return { text, reclaimButtonDisabled, tooltipText }
      }

      if (this.currentEnterprise.originating_system === lunadisPayOrigin) {
        text = i18n.t('RECLAIM_NOW_LUNADIS')
        return { text, reclaimButtonDisabled, tooltipText }
      }

      // Reclaimable expenses exist, continue evaluation
      const today = new Date()
      const mm = String(today.getMonth() + 1) // January is 0!
      const currentYear = today.getFullYear()
      const previousYear = currentYear - 1
      const quarter = this.calculateLatestReclaimableQuarterByOriginatingSystem(
        mm,
        this.currentEnterprise.originating_system
      )
      const quarterRange = quarter > 1 ? `Q1-Q${quarter}` : 'Q1'

      const isQuarterAlreadyReclaimed = reclaimedQuarters.includes(
        `Q${quarter}`
      )

      const isSelectedYearReclaimed = reclaimedQuarters.includes(`Q4`)

      const reclaimNowText =
        this.currentEnterprise.originating_system === lunadisPayOrigin
          ? i18n.t('RECLAIM_NOW_LUNADIS')
          : i18n.t('RECLAIM_NOW')

      if (selectedYear === currentYear) {
        if (isQuarterAlreadyReclaimed || quarter === 4) {
          text = i18n.t('QUARTER_X_IS_NOT_FINISHED', {
            quarter: quarter,
          })
          reclaimButtonDisabled = true
        } else {
          text = `${reclaimNowText} ${quarterRange}`
        }
      } else if (selectedYear === previousYear) {
        if (isSelectedYearReclaimed) {
          text = `${selectedYear} ${i18n.t('IS_ALREADY_RECLAIMED')}`
          reclaimButtonDisabled = true
        } else {
          if (quarter === 4) {
            text = i18n.t('REFUND_DEADLINE_PASSED')
            reclaimButtonDisabled = true
          } else {
            if (this.needMoreInfoSummary && this.needMoreInfoSummary.count) {
              text = `${reclaimNowText} ${selectedYear}`
              reclaimButtonDisabled = true
              tooltipText = i18n.t(
                this.needMoreInfoSummary && this.needMoreInfoSummary.count === 1
                  ? 'RECLAIM_HAS_NEED_MORE_INFO_EXPENSES_SINGULAR'
                  : 'RECLAIM_HAS_NEED_MORE_INFO_EXPENSES',
                {
                  numberOfExpenses:
                    (this.needMoreInfoSummary &&
                      this.needMoreInfoSummary.count) ||
                    0,
                  status: i18n.t('DESELECTED'),
                  country: this.country,
                  year: this.year,
                }
              )
            } else {
              text = `${reclaimNowText} ${selectedYear}`
            }
          }
        }
      } else if (selectedYear < previousYear) {
        if (isSelectedYearReclaimed) {
          text = `${selectedYear} ${i18n.t('IS_ALREADY_RECLAIMED')}`
          reclaimButtonDisabled = true
        } else {
          text = i18n.t('REFUND_DEADLINE_PASSED')
          reclaimButtonDisabled = true
        }
      } else {
        text = i18n.t('SELECTED_YEAR_IS_IN_THE_FUTURE')
        reclaimButtonDisabled = true
      }

      // No POA document found for enterprise
      if (!this.currentEnterprise.can_submit_reclaim) {
        tooltipText = i18n.t('CANNOT_SUBMIT_RECLAIM_TOOLTIP')
        reclaimButtonDisabled = true
      }

      // Reclaim limit not exceded
      const reclaimLimit = this.reclaimLimits[`q${this.quarterToReclaim}`]
      if (
        !this.readyToReclaimSummary.sum_country_currency ||
        this.readyToReclaimSummary.sum_country_currency <= reclaimLimit
      ) {
        tooltipText = i18n.t('AMOUNT_BELOW_RECLAIM_LIMIT_TOOLTIP', {
          amountMinimum: reclaimLimit,
          currency: this.reclaimLimits.currency,
          amountReady: this.$tn(
            this.readyToReclaimSummary.sum_reclaimable_country_currency
          ),
        })
        reclaimButtonDisabled = true
      }
      return { text, reclaimButtonDisabled, tooltipText }
    },
    resetModal() {
      this.resetModalData()
    },
    onCreateReclaim() {
      this.setModalData({
        secondBtnVisible: true,
        secondBtnAction: this.resetModal,
        secondBtnText: i18n.t('CANCEL'),
        confirmAction: this.onConfirmCreateReclaim,
        declineAction: this.resetModal,
        isModalVisible: true,
        confirmText: i18n.t('YES_RECLAIM_NOW'),
        content: i18n.t(
          'ARE_YOU_SURE_YOU_WANT_TO_SUBMIT_YOUR_RECLAIM_FOR_COUNTRY_QUARTER_YEAR',
          {
            country: this.country,
            quarter: this.quarterToReclaim,
            year: this.year,
          }
        ),
        isAlert: false,
        title: i18n.t('CREATE_RECLAIM'),
      })
    },
    onConfirmCreateReclaim() {
      this.$amplitude.getInstance().logEvent('CREATE_RECLAIM', {
        enterpriseId: this.enterpriseId,
        quarter: this.quarterToReclaim,
        totalReclaimed: this.readyToReclaimSummary.sum_enterprise_currency,
      })
      this.createReclaim(`Q${this.quarterToReclaim}`)
      this.resetModalData()
    },
    addExpense() {
      this.$store.dispatch('expenseModal/initializeExpense', {
        country: this.country,
      })
      this.$amplitude.getInstance().logEvent('ADD_EXPENSE', {
        enterpriseId: this.enterpriseId,
      })
    },
    calculateLatestReclaimableQuarterByOriginatingSystem(
      month,
      originatingSystem
    ) {
      const quarter = Math.floor((month - 1) / 3 + 1)
      if (originatingSystem === lunadisPayOrigin) return quarter
      return quarter === 1 ? 4 : quarter - 1
    },
  },
}
</script>
<template>
  <div :class="$style.topSelectors">
    <StatusOverview
      :summary="summary"
      :year="year"
      :loading="
        createReclaimInProgress ||
        reclaimedQuartersInProgress ||
        getSummaryInProgress
      "
      :reclaim-button-text="reclaimButtonText"
      :create-reclaim="onCreateReclaim"
      :reclaim-button-disabled="reclaimButtonDisabled"
      :button-action="addExpense"
      :tooltip-text="tooltipText"
      :tool-tip-disabled="false"
    />
    <div :class="$style.listSelector">
      <ul :class="$style.theTabs">
        <li
          :class="
            status === 'NEED_MORE_INFO'
              ? [$style.needMoreInfo, $style.active]
              : $style.needMoreInfo
          "
          @click="selectStatus('NEED_MORE_INFO')"
          >{{ $t('NEED_MORE_INFO') }}</li
        >
        <li
          :class="
            status === 'DESELECTED'
              ? [$style.deselected, $style.active]
              : $style.deselected
          "
          @click="selectStatus('DESELECTED')"
          >{{ $t('DESELECTED') }}</li
        >
        <li
          :class="
            status === 'NOT_RECLAIMABLE'
              ? [$style.nonRefundable, $style.active]
              : $style.nonRefundable
          "
          @click="selectStatus('NOT_RECLAIMABLE')"
          >{{ $t('NON_REFUNDABLE') }}</li
        >
        <li
          :class="
            status === 'READY_TO_RECLAIM'
              ? [$style.readyForReclaim, $style.active]
              : $style.readyForReclaim
          "
          @click="selectStatus('READY_TO_RECLAIM')"
          >{{ $t('READY_TO_RECLAIM') }}</li
        >
      </ul>
      <div :class="$style.nakedSelector">
        <NakedDropdown
          :id="'customNakedDropdown'"
          :theme="'light'"
          :label="$t('PERIOD')"
          :items="options.data"
          :default-value="year"
          dark
          @change="(year) => onSetYear(year)"
        ></NakedDropdown>
      </div>

      <!-- TODO – Move to all expense tables as in the design: https://projects.invisionapp.com/d/main?origin=v7#/console/17109624/354678731/preview?scrollOffset=1259 -->
      <!-- <NakedDropdown
        :label="$t('EXPENSES_PER_VIEW')"
        :items="[10, 25, 50, 100]"
        :value="pagination.pageSize"
        :default-value="pagination.pageSize"
        color="#fff"
        dark
        @change="(pageSize) => setPageSize(pageSize)"
      ></NakedDropdown>-->
    </div>
    <ExpandableCategories
      :categories="categories"
      :category="category"
      :status="status"
      :category-overview="categoryOverview.categories"
      :select-category="selectCategory"
      :expenses="expenses"
      :pagination="pagination"
      :set-page="onSetPage"
      :check-box-click="checkBoxClick"
      :year="year"
      :enterprise-vat-rate="enterpriseVatRate"
      :loading="getExpensesByCountryInProgress || categoryOverviewInProgress"
      :select-all-click="selectAllClick"
      :selected-expenses="selectedExpenses"
      :country="this.$route.params.country"
    />
    <div v-if="selectedExpenses.length" :class="$style.statusBar">
      <div :class="$style.flexRow">
        <div :class="$style.expenseCount">{{ selectedExpenses.length }}</div>
        <div :class="$style.expensesSelected">
          {{
            selectedExpenses.length === 1
              ? $t('EXPENSE_SELECTED')
              : $t('EXPENSES_SELECTED')
          }}
        </div>
      </div>
      <div :class="$style.statuses">
        <div v-if="status === 'DESELECTED'" :class="$style.status">
          <v-btn
            class="btn status-btn lighter bigger center"
            @click="onMoveExpenses('NEED_MORE_INFO')"
          >
            <div :class="[$style.icon, $style.needMoreInfo]"></div>
            <div>
              {{ $t('MOVE_TO_STATUS', { status: $t('NEED_MORE_INFO') }) }}
            </div>
          </v-btn>
        </div>
        <div v-if="status === 'NEED_MORE_INFO'" :class="$style.status">
          <v-btn
            class="btn status-btn lighter bigger center"
            :loading="moveExpensesInProgress"
            @click="onMoveExpenses('DESELECTED')"
          >
            <div :class="[$style.icon, $style.deselected]"></div>
            <div>{{ $t('MOVE_TO_STATUS', { status: $t('DESELECTED') }) }}</div>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.listSelector {
  display: flex;
  justify-content: space-between;
  height: 55px;
  padding-right: 30px;
  margin: -50px 40px 0;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  background: rgba(lighten($dark, 4%), 0.9);
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.statusBar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  padding: 0 40px;
  background-color: white;
  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.3);
  animation: slidein ease-in-out 0.4s;
}

.statuses {
  display: flex;
}

.status {
  margin-left: 30px;
}

.flexRow {
  display: flex;
}

.icon {
  width: 26px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: left;
  background-size: 16px 16px;
}

.expensesSelected {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 0 0 20px;
  font-size: 18px;
  font-weight: bold;
  color: $dark;
  cursor: default;
}

.expenseCount {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  font-size: 15px;
  font-weight: 600;
  color: white;
  cursor: default;
  background-color: $primary;
  border-radius: 4px;
}

.info {
  float: right;
  margin-right: 50px;
  font-size: 14px;
  font-weight: 300;
  color: $grey;
  span {
    margin-left: 12px;
    font-weight: 600;
    color: white;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.theTabs {
  padding: 0 !important;
  li {
    height: 55px;
    padding: 0 39px 0 55px;
    margin-top: 5px;
    margin-right: 2px;
    font-size: 16px;
    font-weight: 600;
    line-height: 50px;
    color: white;
    background-color: rgba(lighten($dark, 6%), 1);
    background-repeat: no-repeat;
    background-position: calc(0% + 24px) calc(50% - 3px);
    background-size: 17px 19px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transition: all 0.3s cubic-bezier(0.36, 0.69, 0.43, 1.16);
    transform: translate3d(0, -5px, 0);
    &:hover {
      cursor: pointer;
      background-color: rgba(lighten($dark, 9%), 1);
      transition: all 0.1s ease-in-out;
    }
    &.active {
      height: 55px;
      color: $dark;
      cursor: pointer;
      background-color: white;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      transition: all 0.2s cubic-bezier(0.36, 0.69, 0.43, 1.16);
      transform: translate3d(0, -9px, 0);
    }
    &:first-child {
      border-top-left-radius: 7px !important;
    }
  }
}
.readyForReclaim {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 16'%3E%3Cg fill='%2300DBB4' fill-rule='nonzero'%3E%3Cpath d='M12.775 11.5l-3.3-6c-.2-.3-.5-.5-.9-.5h-4c-.4 0-.7.2-.9.5l-3.3 6c-.5.9-.5 2.1 0 3 .6.9 1.5 1.5 2.6 1.5h7.2c1.1 0 2-.6 2.6-1.5.5-.9.5-2 0-3zM4.3078523 2.7c.2.2.4.3.7.3h4c.3 0 .5-.1.7-.3l1-1c.5-.6.3-1.7-.7-1.7h-6c-1 0-1.3 1.1-.7 1.7l1 1z'/%3E%3C/g%3E%3C/svg%3E");
  &.active {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 16'%3E%3Cg fill='%23222f45' fill-rule='nonzero'%3E%3Cpath d='M12.775 11.5l-3.3-6c-.2-.3-.5-.5-.9-.5h-4c-.4 0-.7.2-.9.5l-3.3 6c-.5.9-.5 2.1 0 3 .6.9 1.5 1.5 2.6 1.5h7.2c1.1 0 2-.6 2.6-1.5.5-.9.5-2 0-3zM4.3078523 2.7c.2.2.4.3.7.3h4c.3 0 .5-.1.7-.3l1-1c.5-.6.3-1.7-.7-1.7h-6c-1 0-1.3 1.1-.7 1.7l1 1z'/%3E%3C/g%3E%3C/svg%3E");
  }
}
.needMoreInfo {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23FFBB37' fill-rule='nonzero' d='M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z'/%3E%3C/svg%3E");
  &.active {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23222f45' fill-rule='nonzero' d='M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z'/%3E%3C/svg%3E");
  }
}
.deselected {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg fill='%23E36515' fill-rule='nonzero'%3E%3Cpath d='M3 2h10c.6 0 1-.4 1-1s-.4-1-1-1H3c-.6 0-1 .4-1 1s.4 1 1 1zM15 4H1c-.6 0-1 .4-1 1v10c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1zm-3 7H4V7h2v2h4V7h2v4z'/%3E%3C/g%3E%3C/svg%3E");
  &.active {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg fill='%23222f45' fill-rule='nonzero'%3E%3Cpath d='M3 2h10c.6 0 1-.4 1-1s-.4-1-1-1H3c-.6 0-1 .4-1 1s.4 1 1 1zM15 4H1c-.6 0-1 .4-1 1v10c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1zm-3 7H4V7h2v2h4V7h2v4z'/%3E%3C/g%3E%3C/svg%3E");
  }
}
.nonRefundable {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23D5345D' fill-rule='nonzero' d='M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z'/%3E%3C/svg%3E");
  &.active {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23222f45' fill-rule='nonzero' d='M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm3.5 10.1l-1.4 1.4L8 9.4l-2.1 2.1-1.4-1.4L6.6 8 4.5 5.9l1.4-1.4L8 6.6l2.1-2.1 1.4 1.4L9.4 8l2.1 2.1z'/%3E%3C/svg%3E");
  }
}

.selector {
  width: 30%;
  background: white;
  select {
    width: 100%;
    height: 100%;
    padding: 16px;
    font-size: 16px;
    font-weight: 600;
    color: $dark;
    background-color: transparent;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='8'%3e%3cpath fill='none' fill-rule='evenodd' stroke='%23222F44' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M13 1L7 7 1 1'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 20px top 50%;
    border: none;
    outline: none;
    -webkit-appearance: none;
    &:hover,
    &:focus {
      cursor: pointer;
    }
  }
  .listInfo {
    width: 70%;
    line-height: 50px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='50'%3e%3cpath fill='%23FFF' fill-rule='evenodd' d='M0 0v50h11.438477z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: left top;
  }
}
</style>
