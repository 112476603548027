<script>
export default {
  props: {
    title: {
      default: 'title missing',
      type: String,
    },
    amount: {
      default: '0',
      type: String,
    },
    term: {
      default: '',
      type: String,
    },
    color: {
      default: 'black',
      type: String,
    },
    progress: {
      default: 0,
      type: Number,
      required: true,
    },
    expenses: {
      default: 0,
      type: Number,
    },
  },
}
</script>

<template>
  <div class="status need-more-info">
    <div class="values">
      <div class="category">{{ title }}</div>
      <div class="amount">{{ amount }}</div>
      <div class="explain">{{
        $t(term, {
          interpolation: false,
          value: amount,
          numberOfExpenses: expenses,
        })
      }}</div>
      <div class="progressbar">
        <v-progress-linear
          :value="progress"
          :color="color"
          background-color="bgcolor__grey-dark"
          height="9"
        ></v-progress-linear>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@design';
.status {
  width: 25%;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  .values {
    padding: 25px 35px 35px;
    margin: 0 10px;
    background: rgba(lighten($dark, 4%), 0.9);
    border-radius: 7px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    transition: 1s ease;
    transform: translate3d(0, 0, 0);
    .category {
      margin-bottom: 5px;
      font-size: 12px;
      color: $grey;
    }
    .amount {
      margin-bottom: 5px;
      font-size: 24px;
      font-weight: bold;
      line-height: 25px;
    }
    .explain {
      max-height: 0;
      overflow: hidden;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: $grey;
      transition: 1s ease;
      transform: translate3d(0, 0, 0);
      strong {
        font-weight: 400;
        color: $light-grey;
      }
    }
    .progressbar {
      width: 100%;
      min-width: initial;
      margin-top: 20px;
    }
  }

  &:hover,
  &:focus {
    .values {
      box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.11);
      transition: 1s cubic-bezier(0.23, 1.02, 0.28, 1.12);
      transform: translate3d(0, -40px, 0);
    }
    .explain {
      max-height: 120px;
      transition: 2s cubic-bezier(0.23, 1.02, 0.28, 1.12);
      transform: translate3d(0, 0, 0);
    }
  }
}
</style>
