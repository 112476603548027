<script>
import { lunadisPayOrigin } from '@constants/origins'
import StatusCard from '@components/status-card/status-card'
import { STATUSES } from '@constants/expenseStatuses'
import { getSavedStateSession } from '@utils/storage'
import { enterpriseComputed } from '@state/helpers/enterprise'
export default {
  components: { StatusCard },
  props: {
    summary: {
      type: Array,
      default: () => [],
    },
    createReclaim: {
      type: Function,
      default: () => [],
    },
    year: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    reclaimButtonText: {
      type: String,
      default: '',
    },
    tooltipText: {
      type: String,
      default: '',
    },
    reclaimButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isReadOnlyUser: getSavedStateSession('isReadOnlyUser'),
      lunadisPayOrigin,
    }
  },
  computed: {
    ...enterpriseComputed,
    today() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return today
    },
    reclaimPeriodAfterQuarterEnd() {
      return this.$store.getters['general/getReclaimPeriod']
    },
    lastQuartersMonth() {
      const today = new Date()
      const currentMonth = today.getMonth()
      const monthsInEachQuarter = 3

      const quarter = Math.ceil(currentMonth / 3)
      return quarter * monthsInEachQuarter
    },
    lastQuartersLastDay() {
      const lastQuartersLastDay = new Date(
        this.today.getFullYear(),
        this.lastQuartersMonth,
        0
      ).getDate()

      return new Date(
        this.today.getFullYear(),
        this.lastQuartersMonth - 1,
        lastQuartersLastDay
      )
    },
    lastSubmissionDateForQuarter() {
      return new Date(
        this.today.getFullYear(),
        this.lastQuartersMonth,
        this.reclaimPeriodAfterQuarterEnd - 1,
        29,
        59,
        59,
        999
      )
    },
    showReclaimNotification() {
      return (
        this.currentEnterprise.originating_system === lunadisPayOrigin &&
        this.today > this.lastQuartersLastDay &&
        this.today <= this.lastSubmissionDateForQuarter
      )
    },
    cardsInfo() {
      const allStatusesSum = this.summary
        ? this.summary.reduce((acc, item) => {
            return acc + (item.sum_enterprise_currency || 0)
          }, 0)
        : 0

      return STATUSES.map((statusName) => {
        const statusSummary = this.summary
          ? this.summary.find((item) => item.status === statusName)
          : null
        if (statusSummary) {
          return {
            display: `${
              (statusSummary.sum_enterprise_currency &&
                this.$tn(statusSummary.sum_enterprise_currency)) ||
              0
            } ${statusSummary.enterprise_currency || ''}`,
            percentage:
              Math.ceil(
                100 * (statusSummary.sum_enterprise_currency / allStatusesSum)
              ) || 0,
            expenses: statusSummary.count || 0,
          }
        }
        return {
          display: '0',
          percentage: 0,
          expenses: 0,
        }
      })
    },
  },
  methods: {
    addExpense() {
      this.$store.dispatch('expenseModal/initializeExpense', {
        country: this.$route.params.country,
      })
    },
  },
}
</script>

<template>
  <div class="top-stats">
    <v-alert
      v-if="showReclaimNotification"
      class="reclaim-reminder"
      color="#e36515"
      dark
      type="warning"
      dense
    >
      {{
        $t('RECLAIM_PERIOD_NOTICE', {
          period: lastSubmissionDateForQuarter.toLocaleDateString(),
        })
      }}
    </v-alert>
    <div class="status-overview">
      <div class="top-info">
        <h1>{{ $t(`ALPHA3_COUNTRY.${$route.params.country}`) }}, {{ year }}</h1>
        <v-btn
          v-if="!isReadOnlyUser"
          class="btn ondark-btn addExpenseButton"
          @click="addExpense"
          >+ {{ $t('ADD_EXPENSE') }}</v-btn
        >
        <div class="reclaim">
          <v-tooltip :disabled="!tooltipText.length" max-width="350px" left>
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <v-btn
                  class="btn primary-btn reclaim"
                  :loading="loading"
                  :disabled="isReadOnlyUser || reclaimButtonDisabled"
                  @click="createReclaim"
                  >{{ reclaimButtonText }}</v-btn
                >
              </div>
            </template>
            <span>{{ tooltipText }}</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <v-row>
      <div class="status-cards">
        <StatusCard
          :title="$t('NEED_MORE_INFO')"
          :amount="cardsInfo[1].display"
          :progress="cardsInfo[1].percentage"
          :expenses="cardsInfo[1].expenses"
          color="#ffb920"
          background-color="bgcolor__grey-dark"
          :term="
            cardsInfo[1].expenses
              ? cardsInfo[1].expenses === 1
                ? 'STATUS_CARD_NEEDS_INFO_SINGULAR'
                : 'STATUS_CARD_NEEDS_INFO'
              : 'NO_EXPENSES_IN_THIS_STATUS'
          "
        />
        <StatusCard
          :title="$t('DESELECTED')"
          :amount="cardsInfo[2].display"
          :progress="cardsInfo[2].percentage"
          :expenses="cardsInfo[2].expenses"
          color="#f05e00"
          background-color="bgcolor__grey-dark"
          add-expense-button
          :term="
            cardsInfo[2].expenses === 1
              ? 'STATUS_CARD_DESELECTED_SINGULAR'
              : 'STATUS_CARD_DESELECTED'
          "
        />
        <StatusCard
          :title="$t('NON_REFUNDABLE')"
          :amount="cardsInfo[3].display"
          :progress="cardsInfo[3].percentage"
          :expenses="cardsInfo[3].expenses"
          color="#ff001e"
          background-color="bgcolor__grey-dark"
          :term="
            cardsInfo[3].expenses === 1
              ? 'STATUS_CARD_NON_REFUNDABLE_SINGULAR'
              : 'STATUS_CARD_NON_REFUNDABLE'
          "
        />
        <StatusCard
          :title="$t('READY_TO_RECLAIM')"
          :amount="cardsInfo[0].display"
          :progress="cardsInfo[0].percentage"
          :expenses="cardsInfo[0].expenses"
          color="#00deb2"
          background-color="bgcolor__grey-dark"
          :term="
            cardsInfo[0].expenses
              ? cardsInfo[0].expenses === 1
                ? 'STATUS_CARD_READY_TO_RECLAIM_SINGULAR'
                : 'STATUS_CARD_READY_TO_RECLAIM'
              : 'NO_EXPENSES_IN_THIS_STATUS'
          "
        />
      </div>
    </v-row>
  </div>
</template>

<style lang="scss" scoped>
@import '@design';
.top-stats {
  padding: 30px 42px 100px;
  color: white;
  background: $dark;
}

.status-overview {
  .top-info {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    h1 {
      margin: 0;
      font-size: 32px;
      font-weight: bold;
      color: white;
    }

    .reclaim {
      margin-left: auto;
      text-align: right;
    }
  }
}

.status-cards {
  display: flex;
  width: 100%;
  max-height: 136px;
}

.addExpenseButton {
  margin: 0 0 0 25px;
}

.v-alert {
  margin-top: -15px;
}
</style>
